import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {Evaluation, EvaluationDto, IdeaDto, UserDto} from "../../../api/generated";
import {ideaAverageRating, ideaAverageRatingFormatted, isFilterActive, uniqueCampaignList} from "../../ideas/ideaUtils";
import {evaluationApi} from "../../../api/api-wrapper";
import ImplementEnum = Evaluation.ImplementEnum;
import i18nextko from "../../../bindings/i18nko";
import { departmentName } from "../../../components/elements/user/userUtil";
import { campaignOptions } from "../../campaigns/campaignUtils";
import { dataTableOptions } from "./reportUtils";

class ViewModelContext extends Context {
    evaluations: EvaluationDto[];
}

class IdeaScore {
    public idea: IdeaDto;
    public evaluationScore: number;
    public ideaAverageRating: number;
    public ideaAverageRatingFormatted: string;

    constructor(idea: IdeaDto, evaluation: EvaluationDto) {
        this.idea = idea;
        this.evaluationScore = evaluation.score;
        this.ideaAverageRating = ideaAverageRating(idea);
        this.ideaAverageRatingFormatted = ideaAverageRatingFormatted(idea);
    }
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The evaluations.
     */
    public evaluations: EvaluationDto[];

    /**
     * The options for the campaign filter.
     * - Unique list of campaigns
     * - extracted from the ideas list
     * - to exclude campaigns which have no ideas assigned
     */
    public campaignOptions: { text: string; value: number }[];

    /**
     * The selected campaign filter.
     */
    public campaignFilter: KnockoutObservable<string>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.evaluations = this.filterEvaluations(ctx.evaluations);
        this.campaignOptions = campaignOptions(uniqueCampaignList(this.evaluations.map(evaluation => evaluation.idea)));
        this.campaignFilter = ko.observable("");
    }

    private filterEvaluations(evaluations: EvaluationDto[]): EvaluationDto[] {
        return evaluations.filter(evaluation => evaluation.active && evaluation.submitted && evaluation.score);
    }

    /**
     * Get the evaluations filtered and sorted.
     */
    @autobind
    public evaluationsFiltered(): KnockoutComputed<EvaluationDto[]> {
        return ko.pureComputed(() => {
            return this.evaluations.filter(evaluation => {
                if (this.dateFromFilter() !== undefined
                    && moment(evaluation.idea.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(evaluation.idea.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                if (isFilterActive(this.campaignFilter) &&
                    (!evaluation.idea.campaign || evaluation.idea.campaign.id.toString() != this.campaignFilter())) {
                    return false;
                }
                return true;
            })
        });
    }

    /**
     * Get the ideas score filtered and sorted.
     */
    @autobind
    public ideaScoreFiltered(): KnockoutComputed<IdeaScore[]> {
        return ko.pureComputed(() => {
            return this.evaluationsFiltered()()
                .map(evaluation => new IdeaScore(evaluation.idea, evaluation))
                .sort((score1, score2) => {
                    // sort by evaluation score, then by rating average and then by number of ratings
                    const evaluationScoreCompare = score2.evaluationScore - score1.evaluationScore;
                    const ratingAverageCompare = score2.ideaAverageRating - score1.ideaAverageRating;
                    const ratingCountCompare = score2.idea.ratingsCnt - score1.idea.ratingsCnt;
                    return evaluationScoreCompare != 0 ? evaluationScoreCompare :
                        ratingAverageCompare != 0 ? ratingAverageCompare : ratingCountCompare;
                });
        });
    }




}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./ideasScore.html'),
    componentName: "ideasScore",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Ideen Bewertung`;
        return Promise.all([
            evaluationApi.getEvaluations().then(evaluations => {
                ctx.evaluations = evaluations
            })
        ]);
    }
};
