import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {IdeaDto} from "../../../api/generated";
import {ideaApi} from "../../../api/api-wrapper";

class ViewModelContext extends Context {
    ideas: IdeaDto[];
}

interface IdeaState {
    name: string;
    ideas: number;
    percentage: number;
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The ideas.
     */
    public ideas: IdeaDto[];

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.ideas = ctx.ideas;
    }

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    public ideasFiltered(): KnockoutComputed<IdeaDto[]> {
        return ko.pureComputed(() => {
            return this.ideas.filter(idea => {
                if (this.dateFromFilter() !== undefined
                    && moment(idea.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(idea.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                return true;
            })
        });
    }

    @autobind
    private ideaStates(ideaType: IdeaDto.TypeEnum): KnockoutComputed<IdeaState[]> {
        return ko.pureComputed(() => {
            let ideasForType: IdeaDto[] = this.ideasFiltered()().filter(idea => idea.type == ideaType);
            let ideasTotal = ideasForType.length;

            let ideaMap = new Map();
            ideasForType.forEach(idea => {
                let state = idea.state ? idea.state : "unknown";
                if (ideaMap.has(state)) {
                    ideaMap.set(state, ideaMap.get(state) + 1);
                } else {
                    ideaMap.set(state, 1)
                }
            });

            let ideaStateList: IdeaState[] = [];
            for (let [key, value] of ideaMap) {
                ideaStateList.push({
                    name: key,
                    ideas: value,
                    percentage: this.percentage(ideasTotal, value)
                })
            }
            return ideaStateList;
        });
    }

    public decisionIdeasStates(): KnockoutComputed<IdeaState[]> {
        return this.ideaStates(IdeaDto.TypeEnum.Decision);
    }

    public decisionIdeasCount(): KnockoutComputed<number> {
        return ko.pureComputed(() =>
            this.decisionIdeasStates()()
                .map(ideaState => ideaState.ideas)
                .reduce((prev: number, curr) => prev + curr)
        );
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./ideasState.html'),
    componentName: "ideasState",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Ideen nach Stati`;
        return Promise.all([
            ideaApi.getIdeas().then(ideas => {
                ctx.ideas = ideas;
            })
        ]);
    }
};
