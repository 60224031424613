import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {Evaluation, EvaluationDto} from "../../../api/generated";
import {isFilterActive} from "../../ideas/ideaUtils";
import {evaluationApi} from "../../../api/api-wrapper";
import ImplementEnum = Evaluation.ImplementEnum;

class ViewModelContext extends Context {
    evaluations: EvaluationDto[];
}

interface Bonus {
    name: number;
    counter: number;
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The evaluations.
     */
    public evaluations: EvaluationDto[];

    /**
     * The selected idea type.
     */
    public ideaTypeFilter: KnockoutObservable<string>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.evaluations = this.filterEvaluations(ctx.evaluations);
        this.ideaTypeFilter = ko.observable("");
    }

    private filterEvaluations(evaluations: EvaluationDto[]): EvaluationDto[] {
        return evaluations.filter(evaluation =>
            evaluation.bonus != null && evaluation.bonus >= 0 && evaluation.implement == ImplementEnum.Implement
        );
    }

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    public evaluationsFiltered(): KnockoutComputed<EvaluationDto[]> {
        return ko.pureComputed(() => {
            return this.evaluations.filter(evaluation => {
                if(isFilterActive(this.ideaTypeFilter) && evaluation.idea.type && evaluation.idea.type.toString() !== this.ideaTypeFilter()) {
                    return false;
                }
                if (this.dateFromFilter() !== undefined
                    && moment(evaluation.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(evaluation.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                return true;
            })
        });
    }

    @autobind
    public bonusList(): KnockoutComputed<Bonus[]> {
        return ko.pureComputed(() => {

            let bonusMap = new Map();
            this.evaluationsFiltered()().forEach(evaluation => {
                if(evaluation.bonus) {
                    let bonus = evaluation.bonus;
                    if(bonusMap.has(bonus)) {
                        bonusMap.set(bonus,  bonusMap.get(bonus) +1);
                    } else {
                        bonusMap.set(bonus, 1)
                    }
                }
            });

            let bonusList: Bonus[] = [];
            for (let [key, value] of bonusMap) {
                bonusList.push({
                    name: key,
                    counter: value,
                })
            }

            return bonusList;
        });
    }

    @autobind
    public bonusAverage(): KnockoutComputed<number>  {
        return ko.pureComputed(() => {
            var sum = this.evaluationsFiltered()().length <= 0 ? 0 :
                this.evaluationsFiltered()()
                    .map(evaluation => evaluation.bonus)
                    .reduce((previousValue, currentValue) =>
                        previousValue + currentValue
                    );

            return sum > 0 ? Math.round(sum / this.evaluationsFiltered()().length) : 0;
        });
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./bonusOverview.html'),
    componentName: "bonusOverview",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Prämien Überblick`;
        return Promise.all([
            evaluationApi.getEvaluations().then(evaluations => {
                ctx.evaluations = evaluations
            })
        ]);
    }
};
