import {CampaignDto} from "../../api/generated";
import moment = require("moment");

/**
 * Check whether a campaign is active.
 *
 * @param campaign
 */
export function isActive(campaign: CampaignDto): boolean {
    return campaign.start && campaign.end &&
        moment().startOf('day').isBetween(moment(campaign.start), moment(campaign.end),
            'day', '[]');
}

/**
 * Check whether a campaign has ended.
 *
 * @param campaign
 */
export function hasEnded(campaign: CampaignDto) {
    return !campaign.end || moment().isAfter(moment(campaign.end), 'day');
}

/**
 * Check whether a campaign starts in the future.
 *
 * @param campaign
 */
export function isFuture(campaign: CampaignDto) {
    return campaign.start && moment().isBefore(moment(campaign.start), 'day');
}

/**
 * Get the campaigns as an option list.
 */
export function campaignOptions(campaigns: CampaignDto[]) {
    return campaigns.map(campaign => ({
        text: campaign.title,
        value: campaign.id
    }));
}
