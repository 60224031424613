import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import {BaseReportsViewModel} from "./BaseViewModel";
import {UserDto} from "../../../api/generated";
import {userApi} from "../../../api/api-wrapper";

class ViewModelContext extends Context {
    users: UserDto[];
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The users.
     */
    public users: UserDto[];

    /**
     * Flag whether Admin should be included.
     */
    public includeAdmins: KnockoutObservable<boolean>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.users = ctx.users.filter(user =>
            user.commentsCnt && user.commentsCnt > 0
        );
        this.includeAdmins = ko.observable(false);
    }

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    public usersFiltered(): KnockoutComputed<UserDto[]> {
        return ko.pureComputed(() => {
            return this.users.filter(user => {
                if (!this.includeAdmins() && user.admin) {
                    return false;
                }
                return true;
            })
        });
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./userComments.html'),
    componentName: "userComments",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Mitarbeiter*innen Kommentare`;
        return Promise.all([
            userApi
                .getUsers(null, null, 'full')
                .then(users => ctx.users = users)
        ]);
    }
};

