/**
 * Default data table options for reports.
 */
export function dataTableOptions(): any {
    return {
        dom:"<'row'<'col-12 col-md-4'l><'col-12 col-md-4 text-center pb-md-2'B><'col-12 col-md-4'f>>" +
            "<'row'<'col-12'tr>>" +
            "<'row'<'col-12 col-md-6'i><'col-12 col-md-6'p>>",
        buttons: [
            {
                extend: 'excel',
                className: 'btn-default btn-sm',
                exportOptions: {
                    columns: ':visible',
                    format: {
                        body: function ( data, row, column, node ) {
                            return node.getAttribute('data-value') != null ? node.getAttribute('data-value') : data;
                        }
                    }
                }
            },
            {
                extend: 'print',
                className: 'btn-default btn-sm',
                text: 'Drucken',
                exportOptions: {
                    columns: ':visible',
                    format: {
                        body: function ( data, row, column, node ) {
                            return node.getAttribute('data-value') != null ? node.getAttribute('data-value') : data;
                        }
                    }
                }
            },
            {
                extend: 'colvis',
                className: 'btn-default btn-sm',
                text: 'Spalten ein/ausblenden'
            }
        ],
        deferRender: true,
        paging: true,
        autoWidth: false
    }
}