import {ideaApi} from "../../api/api-wrapper";
import {Context, Router} from "@profiscience/knockout-contrib-router";
import {IdeaDto} from "../../api/generated";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import "../../components/elements/idea/dashboard-list-idea";
import "../../components/elements/cards/card-dashboard-list";
import "../../components/elements/evaluation/dashboard-list-evaluation"
import "../../bindings/knockout-datatables-foreach"
import globalState from "../../global-state";
import {createConfirmModal} from "../../components/elements/modal/modal";
import i18nextko from "../../bindings/i18nko";
import {postbox} from "../../components/util/postbox";
import {dataTableOptions} from "./reports/reportUtils";

class ViewModelContext extends Context {
    ideas: IdeaDto[];
}

class ViewModel {

    /**
     * Deleted ideas.
     */
    public ideas: KnockoutObservableArray<IdeaDto>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        this.ideas = ko.observableArray(ctx.ideas)
    }

    /**
     * Restore the idea with a confirm dialog.
     */
    @autobind
    public restoreIdeaConfirmation(idea: IdeaDto) {
        return createConfirmModal(
            i18nextko.t("idea.edit.restore.confirmText"), i18nextko.t("idea.edit.restore.confirmTitle"),
            i18nextko.t("idea.edit.restore.confirmBtn"),
            i18nextko.t("global.cancel")
        )
        .then(() => {
            globalState.loading(true);
            return ideaApi.restoreIdea(idea.id)
                .then( (idea) => {
                    return Router.update(`/idee/${idea.id}/bearbeiten`, {
                        push: true,
                        force: true
                    });
                })
                .catch(err => {
                    postbox.addError('idea.edit.error.restore');
                    return Promise.reject(err);
                })
                .finally(() => globalState.loading(false));
        })
        .catch(err => {
            // modal canceled
            return Promise.resolve();
        });
    }

    /**
     * Default options for report data tables.
     */
    public dataTableOptions() {
        return dataTableOptions();
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./deletedIdeas.html'),
    componentName: "deletedIdeas",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Gelöschte Ideen`;
        return ideaApi.getIdeasDeleted().then(ideas => ctx.ideas = ideas );
    }
};
