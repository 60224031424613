import {Context} from "@profiscience/knockout-contrib-router";
import {commentApi, ideaApi, rankingApi, userApi} from "../../api/api-wrapper";
import {UserRankingsDto} from "../../api/generated";
import "../../components/elements/ranking/card-rankings";
import globalState from '../../global-state';

export class CommunityViewModelContext extends Context {
    ideasCount: number;
    commentsCount: number;
    usersCount: number;
    rankings: UserRankingsDto;
    rankingsRecent: UserRankingsDto;
    rankingsIdeas: UserRankingsDto;
    rankingsIdeasRecent: UserRankingsDto;
}

export class CommunityViewModel {

    public ideasCount: number;
    public commentsCount: number;
    public usersCount: number;
    public rankings: UserRankingsDto;
    public rankingsRecent: UserRankingsDto;
    public rankingsIdeas: UserRankingsDto;
    public rankingsIdeasRecent: UserRankingsDto;

    /**
     * Constructor.
     * @param ctx
     */
    constructor(ctx: CommunityViewModelContext) {

        this.ideasCount = ctx.ideasCount;
        this.commentsCount = ctx.commentsCount;
        this.usersCount = ctx.usersCount;
        this.rankings = ctx.rankings;
        this.rankingsRecent = ctx.rankingsRecent;
        this.rankingsIdeas = ctx.rankingsIdeas;
        this.rankingsIdeasRecent = ctx.rankingsIdeasRecent;

    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: CommunityViewModel,
    template: require('./community.html'),
    componentName: "community",
    loader: (ctx: CommunityViewModelContext) => {
        document.title = `${document.title} - Community`;
        return Promise.all([
            ideaApi.getIdeasCount().then(count => ctx.ideasCount = count),
            commentApi.getCommentsCount().then(count => ctx.commentsCount = count),
            userApi.getUsersCount().then(count => ctx.usersCount = count),
            rankingApi.getUserRankingPoints(globalState.appVars.rankingsLimit || 10)
                .then(rankings => ctx.rankings = rankings),
            rankingApi.getUserRankingPointsRecent(globalState.appVars.rankingsLimit || 10)
                .then(rankings => ctx.rankingsRecent = rankings),
            rankingApi.getUserRankingIdeas(globalState.appVars.rankingsLimit || 10)
                .then(rankings => ctx.rankingsIdeas = rankings),
            rankingApi.getUserRankingIdeasRecent(globalState.appVars.rankingsLimit || 10)
                .then(rankings => ctx.rankingsIdeasRecent = rankings)
        ]);
    }
};
