import {Context} from "@profiscience/knockout-contrib-router";
import {CategoryDto, UploadDto} from "../../api/generated";
import {categoryApi} from "../../api/api-wrapper";
import {autobind, observable} from "knockout-decorators";
import {FileData} from "../editor/_common";
import * as ko from "knockout";
import {postbox} from "../../components/util/postbox";
import globalState from "../../global-state";
import i18nextko from "../../bindings/i18nko";
import {createConfirmModal} from "../../components/elements/modal/modal";
import {config} from "../../utils/clientConfigWrapper";

class ViewModelContext extends Context {
    categories: CategoryDto[];
}

class CategoryForm {

    /**
     * The category.
     */
    @observable({deep: true, expose: true})
    public category: CategoryDto;

    /**
     * The category name.
     */
    public categoryName: KnockoutObservable<string>;

    /**
     * The category image.
     */
    public imageData: KnockoutObservable<FileData>;

    /**
     * Flag if the upload fields are visible.
     */
    public editMode: KnockoutObservable<boolean>;

    /**
     * Constructor.
     *
     * @param category
     */
    constructor(category: CategoryDto) {
        this.category = category;
        this.categoryName = ko.observable(category.name);
        this.editMode = ko.observable(false);
        this.imageData = ko.observable(this.createImageData());
    }

    @autobind
    public saveCategory() {
        if (this.imageData() && this.imageData().base64String()) {
            if (this.imageData().file().type.search(/^image\/.*$/) == -1) {
                postbox.addError('widget.image.error.invalidType');
                return Promise.reject();
            }
            this.category.defaultImageUpload = this.toUploadDto();
        }
        globalState.loading(true);
        this.category.name = this.categoryName();

        categoryApi.putCategory(this.category, this.category.id).then(category => {
            this.category = category;
            this.toggleEditMode();
            this.resetImageData();
        })
            .catch(err => console.error(err))
            .finally(() => globalState.loading(false));
    }

    @autobind
    public savedEnabled() {
        return this.categoryName() && this.categoryName().trim().length > 0;
    }

    @autobind
    public toggleEditMode() {
        this.editMode(!this.editMode());
    }

    @autobind
    private toUploadDto(): UploadDto {
        return {
            filename: this.imageData().file().name,
            mainImage: false,
            mimeType: this.imageData().file().type,
            data: this.imageData().base64String()
        }
    }

    @autobind
    private resetImageData() {
        this.imageData().dataURL(null);
        this.imageData().base64String(null);
        this.imageData().file(null);
    }

    private createImageData() {
        return {
            dataURL: ko.observable(),
            base64String: ko.observable(),
            file: ko.observable()
        };
    }

    /**
     * The options for the file upload field.
     */
    public imageFileOptions() {
        return {
            noFileText: i18nextko.t("global.fileUpload.noFileText"),
            buttonText: i18nextko.t("global.fileUpload.button.choose"),
            changeButtonText: i18nextko.t("global.fileUpload.button.change"),
            clearButtonText: i18nextko.t("global.fileUpload.button.clear")
        };
    }
}


class ViewModel {

    /**
     * Name to add a new category.
     */
    public categoryName: KnockoutObservable<string>;

    /**
     * Check whether a new category can be added - the name is not empty.
     */
    public addCategoryEnabled: KnockoutComputed<boolean>

    /**
     * The categories .
     */
    public categoryForms: KnockoutObservableArray<CategoryForm>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        this.categoryName = ko.observable(null);
        this.addCategoryEnabled = ko.pureComputed(() =>
            this.categoryName() && this.categoryName().trim().length > 0
        );
        this.categoryForms = ko.observableArray(
            ctx.categories.map(category => new CategoryForm(category))
        );
        console.debug("Categories", ctx.categories);
    }

    @autobind
    public addCategory() {
        globalState.loading(true);
        categoryApi.postCategory({name: this.categoryName()})
            .then(category => {
                this.categoryForms.push(new CategoryForm(category))
                this.categoryName("");
            })
            .catch(err => console.error(err))
            .finally(() => globalState.loading(false));
    }

    @autobind
    public deleteCategoryConfirmation(categoryForm: CategoryForm) {
        return createConfirmModal(
            i18nextko.t("category.delete.confirmText"),
            i18nextko.t("category.delete.confirmTitle"),
            i18nextko.t("global.delete"),
            i18nextko.t("global.cancel")
        )
            .then(() => {
                globalState.loading(true);
                return categoryApi.deleteCategory(categoryForm.category.id).then(() => {
                    postbox.addSuccess('category.delete.success');
                    this.categoryForms.splice(this.categoryForms.indexOf(categoryForm), 1);
                })
                    .catch(err => {
                        postbox.addError('category.delete.error');
                    })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => globalState.loading(false));
    }

    /**
     * Get the styles for the category default image.
     * @param category
     */
    public categoryImageStyle(category: CategoryDto) {
        const img = category.defaultImage;
        return img ? `background-image: url('${config.attachmentEndpoint}${img}');` : "";
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./categories.html'),
    componentName: "categories",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Kategorien`;
        return categoryApi.getCategories().then(categories => ctx.categories = categories);
    }
};
