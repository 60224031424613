import globalState from "../../../global-state";
import "../../../bindings/knockout-datatables-foreach"
import "../../../components/elements/reports/report-filters"
import {Context} from "@profiscience/knockout-contrib-router";
import {EvaluationDto, IdeaDto} from "../../../api/generated";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import * as moment from "moment";
import {dataTableOptions} from "./reportUtils";


export class BaseReportsViewModel {

    /**
     * The date from filter.
     */
    public dateFromFilter: KnockoutObservable<Date>;

    /**
     * The date to filter.
     */
    public dateToFilter: KnockoutObservable<Date>;

    /**
     * Constructor.
     */
    constructor() {
        this.dateFromFilter = globalState.reportsSearchFilters.dateFromFilter;
        this.dateToFilter = globalState.reportsSearchFilters.dateToFilter;
    }

    /**
     * Default options for report data tables.
     */
    public dataTableOptions() {
        return dataTableOptions();
    }

    /**
     * Get the percentage rounded up to max 2 decimals if needed.
     * see https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
     * @param total
     * @param amount
     */
    public percentage(total: number, amount: number): number {
        return Math.round(100 * amount / total * 100) / 100;
    }

    /**
     * Get the config value for recent interval days
     * to show the user what current activities means.
     */
    public recentIntervalDays() {
        return globalState.appVars.recentIntervalDays;
    }
}

export class IdeaReportsViewModelContext extends Context {
    ideas: IdeaDto[];
}

export class IdeaReportsViewModel extends BaseReportsViewModel {

    /**
     * The ideas.
     */
    public ideas: IdeaDto[];

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: IdeaReportsViewModelContext) {
        super();
        this.ideas = ctx.ideas;
    }

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    public ideasFiltered(): KnockoutComputed<IdeaDto[]> {
        return ko.pureComputed(() => {
            return this.ideas.filter(idea => {
                if (this.dateFromFilter() !== undefined
                    && moment(idea.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(idea.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                return true;
            })
        });
    }

}

export class EvaluationReportsViewModelContext extends Context {
    evaluations: EvaluationDto[];
}

export class EvaluationReportsViewModel extends BaseReportsViewModel {

    /**
     * The evaluations.
     */
    public evaluations: EvaluationDto[];

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: EvaluationReportsViewModelContext) {
        super();
        this.evaluations = ctx.evaluations;
    }

    /**
     * Get the evaluations filtered and sorted.
     */
    @autobind
    public evaluationsFiltered(): KnockoutComputed<EvaluationDto[]> {
        return ko.pureComputed(() => {
            return this.evaluations.filter(evaluation => {
                if (this.dateFromFilter() !== undefined
                    && moment(evaluation.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(evaluation.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                return true;
            })
        });
    }

}
