import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {EvaluationDto} from "../../../api/generated";
import {evaluationApi} from "../../../api/api-wrapper";

class ViewModelContext extends Context {
    evaluations: EvaluationDto[];
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The evaluations.
     */
    public evaluations: EvaluationDto[];

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.evaluations = this.filterEvaluations(ctx.evaluations);
    }

    private filterEvaluations(evaluations: EvaluationDto[]): EvaluationDto[] {
        return evaluations.filter(evaluation =>
            evaluation.evaluated !== true || evaluation.submittedTimestamp === null || evaluation.submitted == false
        );
    }

    /**
     * Get the evaluations filtered.
     */
    @autobind
    public evaluationsFiltered(): KnockoutComputed<EvaluationDto[]> {
        return ko.pureComputed(() => {
            return this.evaluations.filter(evaluation => {
                if (this.dateFromFilter() !== undefined
                    && moment(evaluation.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(evaluation.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                return true;
            })
        });
    }

    public daysPassed(created: Date) {
        return moment().diff(moment(created), 'days');
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./evaluators.html'),
    componentName: "evaluators",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Juror*innen`;
        return Promise.all([
            evaluationApi.getEvaluations().then(evaluations => {
                ctx.evaluations = evaluations
            })
        ]);
    }
};
