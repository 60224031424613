import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {Evaluation, EvaluationDto, UserDto} from "../../../api/generated";
import {isFilterActive} from "../../ideas/ideaUtils";
import {evaluationApi} from "../../../api/api-wrapper";
import ImplementEnum = Evaluation.ImplementEnum;
import i18nextko from "../../../bindings/i18nko";
import { departmentName } from "../../../components/elements/user/userUtil";

class ViewModelContext extends Context {
    evaluations: EvaluationDto[];
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The ideas.
     */
    public evaluations: EvaluationDto[];

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.evaluations = this.filterEvaluations(ctx.evaluations);
    }

    private filterEvaluations(evaluations: EvaluationDto[]): EvaluationDto[] {
        return evaluations.filter(evaluation =>
            evaluation.bonus != null && evaluation.bonus >= 0 && evaluation.implement
        );
    }

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    public evaluationsFiltered(): KnockoutComputed<EvaluationDto[]> {
        return ko.pureComputed(() => {
            return this.evaluations.filter(evaluation => {
                if (this.dateFromFilter() !== undefined
                    && moment(evaluation.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(evaluation.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                return true;
            })
        });
    }

    public bonus(evaluation: EvaluationDto) {
        if(evaluation.implement) {
            if(evaluation.implement == EvaluationDto.ImplementEnum.Implement) {
                return evaluation.bonus;
            } else {
                return 0;
            }
        }
        return '-';
    }

    public implementationDescriptionAvailable(evaluation: EvaluationDto) {
        if(evaluation.implementationDescription && evaluation.implementationDescription.trim().length > 0) {
            return i18nextko.t('global.yes');
        }
        return i18nextko.t('global.no');
    }

    public assessmentCriteriaDate(evaluation: EvaluationDto) {
        if(evaluation.assessmentCriteriaList.length > 0) {
            return evaluation.assessmentCriteriaList[0].timestamp
        }
        return null;
    }

    public departmentName(user: UserDto) {
        return departmentName(user.department);
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./evaluationsOverview.html'),
    componentName: "evaluationsOverview",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Bewertungen Überblick`;
        return Promise.all([
            evaluationApi.getEvaluations().then(evaluations => {
                ctx.evaluations = evaluations
            })
        ]);
    }
};
