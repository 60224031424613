import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {IdeaDto} from "../../../api/generated";
import {isFilterActive} from "../../ideas/ideaUtils";
import {ideaApi} from "../../../api/api-wrapper";

class ViewModelContext extends Context {
    ideas: IdeaDto[];
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The ideas.
     */
    public ideas: IdeaDto[];

    /**
     * The selected idea type.
     */
    public ideaTypeFilter: KnockoutObservable<string>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.ideas = this.filterActiveIdeas(ctx.ideas);
        this.ideaTypeFilter = ko.observable("");
    }

    private filterActiveIdeas(ideas: IdeaDto[]): IdeaDto[] {
        return ideas.filter(idea =>
            idea.state != IdeaDto.StateEnum.Submission &&
            idea.state != IdeaDto.StateEnum.Implemented &&
            idea.state != IdeaDto.StateEnum.Canceled &&
            idea.state != IdeaDto.StateEnum.NotNew
        );
    }

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    public ideasFiltered(): KnockoutComputed<IdeaDto[]> {
        return ko.pureComputed(() => {
            return this.ideas.filter(idea => {
                if (this.dateFromFilter() !== undefined
                    && moment(idea.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(idea.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                if(isFilterActive(this.ideaTypeFilter) && idea.type && idea.type.toString() !== this.ideaTypeFilter()) {
                    return false;
                }
                return true;
            })
        });
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./ideasActive.html'),
    componentName: "ideasActive",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Aktive Ideen`;
        return Promise.all([
            ideaApi.getIdeas().then(ideas => {
                ctx.ideas = ideas;
            })
        ]);
    }
};
