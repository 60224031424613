import {Context} from "@profiscience/knockout-contrib-router";
import {categoryApi, ideaApi} from "../../api/api-wrapper";
import {IdeaDto} from "../../api/generated";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import globalState from "../../global-state";
import {isFilterActive} from "../ideas/ideaUtils";
import * as moment from "moment";
import "../../bindings/knockout-datatables-foreach"

class ViewModelContext extends Context {
    ideas: IdeaDto[];
}

class ViewModel {

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        console.log(ctx.ideas[0]);
        this.ideas = ctx.ideas;
    }

    /**
     * The ideas.
     */
    public ideas: Array<IdeaDto>;

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    public ideasFiltered(): KnockoutComputed<IdeaDto[]> {
        return ko.pureComputed(() => this.ideas);
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./reports.html'),
    componentName: "reports",
    loader: (ctx: ViewModelContext) => {
        return Promise.all([
            ideaApi.getIdeas().then(ideas => {
                ctx.ideas = ideas;
            })
        ]);
    }
};
