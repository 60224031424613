import {CategoryDto} from "../../api/generated";
import i18nextko from "../../bindings/i18nko";


/**
 * Get the category name or the translation if it exists.
 *
 * @param category
 */
export function categoryNameTranslated(category: CategoryDto) {
    const key = "categories." + category.name.toLowerCase();
    const translated = i18nextko.t(key)();
    return key == translated ? category.name : translated;
}