import * as ko from "knockout";
import {Context} from "@profiscience/knockout-contrib-router";
import {BaseReportsViewModel} from "./BaseViewModel";
import {IdeaDto, IdeaStateDurationDto} from "../../../api/generated";
import {ideaApi} from "../../../api/api-wrapper";
import "knockout.chart";
import {autobind, observableArray} from "knockout-decorators";
import * as moment from "moment";
import StateEnum = IdeaStateDurationDto.StateEnum;

class ViewModelContext extends Context {
    ideaStateDurations: IdeaStateDurationDto[];
}

/**
 * Groups the IdeaStateDuration for a single Idea.
 * Uses an IdeaStateDurationSlim object to store the reference to the Idea only once.
 */
class IdeaStates {

    idea: IdeaDto;
    stateDurationsSlim: IdeaStateDurationSlim[];

    constructor(stateDuration: IdeaStateDurationDto) {
        this.idea = stateDuration.idea;
        this.stateDurationsSlim = [];
        this.addStateDuration(stateDuration);
    }

    public addStateDuration(stateDuration: IdeaStateDurationDto) {
        this.stateDurationsSlim.push(new IdeaStateDurationSlim(stateDuration));
    }

    private getStateDuration(state: IdeaStateDurationDto.StateEnum): IdeaStateDurationSlim {
        return this.stateDurationsSlim.find(stateDurationsSlim => stateDurationsSlim.state == state);
    }

    public getDuration(state: IdeaStateDurationDto.StateEnum): number {
        return this.getStateDuration(state) ? this.getStateDuration(state).duration : 0;
    }
}

class IdeaStateDurationSlim {
    state: IdeaStateDurationDto.StateEnum;
    duration: number;
    startDate: string;
    endDate: string;

    constructor(stateDuration: IdeaStateDurationDto) {
        this.state = stateDuration.state;
        this.duration = stateDuration.duration || 0;
        this.startDate = stateDuration.startDate || null;
        this.endDate = stateDuration.startDate || null;
    }
}

/**
 *
 * Collects the durations and number of ideas in a single state
 * to calculate the average duration.
 */
class AverageStateDuration {

    duration: number;
    ideas: number;
    state: IdeaStateDurationDto.StateEnum;

    constructor(ideaStateDuration: IdeaStateDurationDto) {
        this.duration = ideaStateDuration.duration
        this.ideas = 1;
        this.state = ideaStateDuration.state;
    }

    public addIdeaStateDuration(ideaStateDuration: IdeaStateDurationDto) {
        this.duration += ideaStateDuration.duration;
        this.ideas += 1;
    }

    public getAverage() {
        return Math.round(this.duration / this.ideas);
    }
}


class ViewModel extends BaseReportsViewModel {

    @observableArray
    public ideaStateDurations: IdeaStateDurationDto[];

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();

        this.dateFromFilter = ko.observable(null);
        this.dateToFilter = ko.observable(null);
        // Exclude non relevant states
        this.ideaStateDurations = ctx.ideaStateDurations.filter(stateDuration =>
            stateDuration.state != StateEnum.Submission &&
            stateDuration.state != StateEnum.Implemented &&
            stateDuration.state != StateEnum.Canceled &&
            stateDuration.state != StateEnum.Unknown &&
            stateDuration.state != StateEnum.NotNew
        );
    }

    @autobind
    public ideaStateDurationsFiltered(): KnockoutComputed<IdeaStateDurationDto[]> {
        return ko.pureComputed(() => {
            return this.ideaStateDurations.filter(stateDuration => {
                if (this.dateFromFilter() !== undefined
                    && moment(stateDuration.idea.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(stateDuration.idea.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                return true;
            });
        });
    }

    /**
     * Converts a list of IdeaStateDurationDto to a list of IdeaStates,
     * which groups the states for each Idea.
     */
    @autobind
    public ideaStatesFiltered(): KnockoutComputed<IdeaStates[]> {
        return ko.pureComputed(() => {
            const ideaStatesMap: Map<number, IdeaStates> = this.ideaStateDurationsFiltered()()
                .reduce((map: any, ideaStateDurationDto: IdeaStateDurationDto) => {
                    const key = ideaStateDurationDto.idea.id;
                    if (!map.has(key)) {
                        map.set(key, new IdeaStates(ideaStateDurationDto));
                    } else {
                        map.get(key).addStateDuration(ideaStateDurationDto);
                    }
                    return map;
                }, new Map<string, IdeaStates>());

            return Array.from(ideaStatesMap.values());
        });
    }

    @autobind
    public averageStatesDurationsFiltered(): any {
        return ko.pureComputed(() => {
            const averageDurationsMap: Map<string, AverageStateDuration> = this.ideaStateDurationsFiltered()()
                .reduce((map: any, ideaStateDuration: IdeaStateDurationDto) => {
                    const key = ideaStateDuration.state;
                    if (!map.has(key)) {
                        map.set(key, new AverageStateDuration(ideaStateDuration));
                    } else {
                        map.get(key).addIdeaStateDuration(ideaStateDuration);
                    }
                    return map;
                }, new Map<string, AverageStateDuration>());

            return Array.from(averageDurationsMap.values());
        });
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./ideasStatesDuration.html'),
    componentName: "ideasStatesDuration",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Dauer Prozessschritte`;
        return ideaApi.getIdeaStateDurations().then(ideaStateDurations => {
            ctx.ideaStateDurations = ideaStateDurations || [];
        });
    }
};
