import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {CategoryDto, IdeaDto, UserDto} from "../../../api/generated";
import {ideaAverageRating, ideaAverageRatingFormatted, ideaStateOptions} from "../../ideas/ideaUtils";
import {ideaApi} from "../../../api/api-wrapper";
import {categoryNameTranslated} from "../categoryUtils";
import {departmentName} from "../../../components/elements/user/userUtil";
import {App} from "../../../app";
import i18nextko from "../../../bindings/i18nko";

class ViewModelContext extends Context {
    ideas: IdeaDto[];
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The ideas.
     */
    public ideas: IdeaDto[];

    /**
     * The selected idea states.
     */
    public ideaStateFilter: KnockoutObservableArray<string>;

    public showAnonymous: KnockoutObservable<boolean>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.ideas = ctx.ideas;
        this.ideaStateFilter = ko.observableArray([]);
        this.showAnonymous = ko.observable(false);
    }

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    public ideasFiltered(): KnockoutComputed<IdeaDto[]> {
        return ko.pureComputed(() => {
            return this.ideas.filter(idea => {
                if (this.dateFromFilter() !== undefined
                    && moment(idea.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(idea.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                if (this.ideaStateFilter() && this.ideaStateFilter().length > 0
                    && idea.state && !this.ideaStateFilter().includes(idea.state.toString())) {
                    return false;
                }
                return true;
            })
        });
    }

    /**
     * Get the options for the idea type filter.
     * State submission is not visible.
     */
    public ideaStateOptions() {
        return ideaStateOptions()
            .filter(option => option.value != 'submission');
    }

    /**
     * Get the category name or the translation if it exists.
     * @param category
     */
    public categoryName(category: CategoryDto) {
        return categoryNameTranslated(category);
    }

    public departmentName(user: UserDto) {
        return departmentName(user.department);
    }

    public ideaAverageRatingFormatted(idea: IdeaDto) {
        return ideaAverageRatingFormatted(idea);
    }

    public ideaAverageRating(idea: IdeaDto) {
        return ideaAverageRating(idea);
    }

    public username(idea: IdeaDto) {
        if (this.showAnonymous() || !idea.anonymous) {
            return App.username(idea.user);

        } else {
            return i18nextko.t('idea.anonymousUser')();
        }
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./ideasOverview.html'),
    componentName: "ideasOverview",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Ideen Überblick`;
        return Promise.all([
            ideaApi
                .getIdeas(null, null, null, 'full')
                .then(ideas => ctx.ideas = ideas)
        ]);
    }
};
