import {IdeaReportsViewModel, IdeaReportsViewModelContext} from "./BaseViewModel";
import {ideaApi} from "../../../api/api-wrapper";

export default <KnockoutLazyPageDefinition>{
    viewModel: IdeaReportsViewModel,
    template: require('./ideasComments.html'),
    componentName: "ideasComments",
    loader: (ctx: IdeaReportsViewModelContext) => {
        document.title = `${document.title} - Report Ideen Kommentare`;
        return Promise.all([
            ideaApi.getIdeas().then(ideas => {
                ctx.ideas = ideas;
            })
        ]);
    }
};
