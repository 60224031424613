import * as ko from "knockout";
import {commentApi, ideaApi, rankingApi, userApi} from "../../../api/api-wrapper";
import {CommunityViewModel, CommunityViewModelContext} from "../../community/community";
import globalState from "../../../global-state";
import {dataTableOptions} from "./reportUtils";
import {UserRankingDto} from "../../../api/generated";
import "../../../bindings/knockout-datatables-foreach"

class ViewModel extends CommunityViewModel {

    /**
     * Constructor.
     * @param ctx
     */
    constructor(ctx: CommunityViewModelContext) {
        super(ctx);
    }

    public getRankingIdeasTop():KnockoutComputed<UserRankingDto[]> {
        return ko.pureComputed(() => this.rankingsIdeas.top);
    }

    public getRankingIdeasRecentTop():KnockoutComputed<UserRankingDto[]> {
        return ko.pureComputed(() => this.rankingsIdeasRecent.top);
    }

    public getRankingActivitiesTop():KnockoutComputed<UserRankingDto[]> {
        return ko.pureComputed(() => this.rankings.top);
    }

    public getRankingActivitiesRecentTop():KnockoutComputed<UserRankingDto[]> {
        return ko.pureComputed(() => this.rankingsRecent.top);
    }

    /**
     * Default options for report data tables.
     */
    public dataTableOptions() {
        return dataTableOptions();
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./communityOverview.html'),
    componentName: "communityOverview",
    loader: (ctx: CommunityViewModelContext) => {
        document.title = `${document.title} - Report Community Überblick`;
        return Promise.all([
            ideaApi.getIdeasCount().then(count => ctx.ideasCount = count),
            commentApi.getCommentsCount().then(count => ctx.commentsCount = count),
            userApi.getUsersCount().then(count => ctx.usersCount = count),
            rankingApi.getUserRankingPoints(globalState.appVars.rankingsLimit || 10)
                .then(rankings => ctx.rankings = rankings),
            rankingApi.getUserRankingPointsRecent(globalState.appVars.rankingsLimit || 10)
                .then(rankings => ctx.rankingsRecent = rankings),
            rankingApi.getUserRankingIdeas(globalState.appVars.rankingsLimit || 10)
                .then(rankings => ctx.rankingsIdeas = rankings),
            rankingApi.getUserRankingIdeasRecent(globalState.appVars.rankingsLimit || 10)
                .then(rankings => ctx.rankingsIdeasRecent = rankings)
        ]);
    }
};
