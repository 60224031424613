import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {EvaluationAssessmentCriteriaDto, EvaluationDto} from "../../../api/generated";
import {evaluationApi} from "../../../api/api-wrapper";
import {averageScore} from "../../evaluation/evaluationUtils";
import {App} from "../../../app";

interface Feedback {
    id: number;
    name: string;
    count: number;
    assessments: number;
    score: number;
}

class ViewModelContext extends Context {
    evaluations: EvaluationDto[];
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The evaluations.
     */
    public evaluations: EvaluationDto[];

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.evaluations = ctx.evaluations;
    }

    /**
     * Get the evaluations filtered and sorted.
     */
    @autobind
    public evaluationsFiltered(): KnockoutComputed<EvaluationDto[]> {
        return ko.pureComputed(() => {
            return this.evaluations.filter(evaluation => {
                if ((this.dateFromFilter() || this.dateToFilter()) &&
                    !this.assessmentAvailable(evaluation)) {
                    // date filter should check the assessment date
                    // all evaluations without assessment will not be shown if a date filter is active.
                    return false;
                }
                if (this.dateFromFilter() &&
                    moment(evaluation.assessmentCriteriaList[0].timestamp).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() &&
                    moment(evaluation.assessmentCriteriaList[0].timestamp).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                return true;
            })
        });
    }

    /**
     * Total number of assessed evaluations.
     */
    @autobind
    public assessmentsTotal(): KnockoutComputed<number> {
        return ko.pureComputed(() => {
            return this.evaluationsFiltered()().filter(evaluation => this.assessmentAvailable(evaluation)).length;
        });
    }

    /**
     * Average score for all assessed evaluations.
     */
    @autobind
    public assessmentsAverageScore(): KnockoutComputed<number> {
        return ko.pureComputed(() => {
            const assessedEvaluations = this.evaluationsFiltered()().filter(evaluation => this.assessmentAvailable(evaluation));
            return this.averageScore(assessedEvaluations);
        });
    }

    /**
     * List of expertUsers and the feedbacks which they received so far.
     */
    public evaluators(): KnockoutComputed<Feedback[]> {
        return ko.pureComputed(() => {

            let evaluationMap: Map<number, EvaluationDto[]> = new Map();
            this.evaluationsFiltered()().forEach(evaluation => {
                let expertUserId = evaluation.expertUser.id;
                if (evaluationMap.has(expertUserId)) {
                    evaluationMap.get(expertUserId).push(evaluation);
                } else {
                    evaluationMap.set(expertUserId, [evaluation]);
                }
            });

            let feedbacks: Feedback[] = [];
            for (let [id, evaluationsList] of evaluationMap) {
                feedbacks.push({
                    id: id,
                    name: App.username(evaluationsList[0].expertUser),
                    count: evaluationsList.length,
                    assessments: evaluationsList.filter(evaluation => this.assessmentAvailable(evaluation)).length,
                    score: this.averageScore(evaluationsList)
                })
            }

            return feedbacks;
        });
    }

    /**
     * List of submitters and the feedbacks which they wrote so far.
     */
    public submitters(): KnockoutComputed<Feedback[]> {
        return ko.pureComputed(() => {

            let evaluationMap: Map<number, EvaluationDto[]> = new Map();
            this.evaluationsFiltered()().forEach(evaluation => {
                let submitterId = evaluation.idea.user.id;
                if (evaluationMap.has(submitterId)) {
                    evaluationMap.get(submitterId).push(evaluation);
                } else {
                    evaluationMap.set(submitterId, [evaluation]);
                }
            });

            let feedbacks: Feedback[] = [];
            for (let [id, evaluationsList] of evaluationMap) {
                feedbacks.push({
                    id: id,
                    name: App.username(evaluationsList[0].idea.user),
                    count: evaluationsList.length,
                    assessments: evaluationsList.filter(evaluation => this.assessmentAvailable(evaluation)).length,
                    score: this.averageScore(evaluationsList)
                })
            }

            return feedbacks;
        });
    }

    /**
     * Average feedback score for a list of evaluations.
     * @param evaluations
     */
    private averageScore(evaluations: EvaluationDto[]): number {
        let assessmentCriterias: EvaluationAssessmentCriteriaDto[] = [];
        evaluations
            .filter(evaluation => this.assessmentAvailable(evaluation))
            .forEach(evaluation =>
                evaluation.assessmentCriteriaList.forEach(
                    assessmentCriteria => assessmentCriterias.push(assessmentCriteria)
                )
            );

        const scoreTotal = assessmentCriterias.reduce((previousValue, criteria) =>
            previousValue + criteria.score, 0);
        return averageScore(scoreTotal, assessmentCriterias.length)
    }

    /**
     * Is a feedback available for this evaluation?
     *
     * @param evaluation
     */
    private assessmentAvailable(evaluation: EvaluationDto) {
        return evaluation.assessmentCriteriaList && evaluation.assessmentCriteriaList.length > 0
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./feedbacks.html'),
    componentName: "feedbacks",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Bewertungen Feedback`;
        return Promise.all([
            evaluationApi.getEvaluations().then(evaluations => {
                ctx.evaluations = evaluations
            })
        ]);
    }
};
