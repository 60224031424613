import {IdeaReportsViewModel, IdeaReportsViewModelContext} from "./BaseViewModel";
import {ideaApi} from "../../../api/api-wrapper";
import {IdeaDto} from "../../../api/generated";
import {ideaAverageRatingFormatted, ideaAverageRating} from "../../ideas/ideaUtils";

class ViewModel extends IdeaReportsViewModel {

    public ideaAverageRatingFormatted(idea: IdeaDto) {
        return ideaAverageRatingFormatted(idea);
    }

    public ideaAverageRating(idea: IdeaDto) {
        return ideaAverageRating(idea);
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./ideasLikes.html'),
    componentName: "ideasLikes",
    loader: (ctx: IdeaReportsViewModelContext) => {
        document.title = `${document.title} - Report Ideen Mitarbeiter*innen Bewertungen`;
        return Promise.all([
            ideaApi.getIdeas().then(ideas => {
                ctx.ideas = ideas;
            })
        ]);
    }
};
