import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {Idea, IdeaDto, UserDto} from "../../../api/generated";
import {isFilterActive} from "../../ideas/ideaUtils";
import {ideaApi} from "../../../api/api-wrapper";
import {App} from "../../../app";
import StateEnum = Idea.StateEnum;

class Submitter {
    id: number;
    name: string;
    ideasTotal: number;
    ideasStateMap: Map<IdeaDto.StateEnum, number>;

    constructor(user: UserDto) {
        this.id = user.id;
        this.name = App.username(user);
        this.ideasTotal = 0;
        this.ideasStateMap = new Map<IdeaDto.StateEnum, number>();
        Object.keys(IdeaDto.StateEnum)
            .filter((key, idx) => idx % 2 == 0)
            .forEach(state => this.ideasStateMap.set(StateEnum[state], 0));
    }

    public increaseStateCount(state: IdeaDto.StateEnum) {
        this.ideasTotal++;
        this.ideasStateMap.set(state, this.ideasStateMap.get(state) + 1)
    }

    public stateCounts(): number[] {
        return Array.from(this.ideasStateMap.values());
    }
}

class ViewModelContext extends Context {
    ideas: IdeaDto[];
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The ideas.
     */
    public ideas: IdeaDto[];

    /**
     * The selected idea type.
     */
    public ideaTypeFilter: KnockoutObservable<string>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.ideas = ctx.ideas;
        this.ideaTypeFilter = ko.observable("");
    }

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    public ideasFiltered(): KnockoutComputed<IdeaDto[]> {
        return ko.pureComputed(() => {
            return this.ideas.filter(idea => {
                if (this.dateFromFilter()
                    && moment(idea.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter()
                    && moment(idea.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                if (isFilterActive(this.ideaTypeFilter) && idea.type && idea.type.toString() !== this.ideaTypeFilter()) {
                    return false;
                }
                return true;
            })
        });
    }

    @autobind
    public submitters(): KnockoutComputed<Submitter[]> {
        return ko.pureComputed(() => {
            let submitterMap = new Map<number, Submitter>();
            this.ideasFiltered()().forEach(idea => {
                if (!submitterMap.has(idea.user.id)) {
                    submitterMap.set(idea.user.id, new Submitter(idea.user));
                }
                submitterMap.get(idea.user.id).increaseStateCount(idea.state);
            });
            return Array.from(submitterMap.values());
        });
    }

    public ideaStates() {
        return Object.keys(IdeaDto.StateEnum)
            .filter((key, idx) => idx % 2 != 0)
            .map(state => state.toLowerCase());
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./ideasSubmitter.html'),
    componentName: "ideasSubmitter",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Einreicher*innen`;
        return Promise.all([
            ideaApi.getIdeas().then(ideas => {
                ctx.ideas = ideas;
            })
        ]);
    }
};
