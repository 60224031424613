import * as ko from "knockout";
import {Context} from "@profiscience/knockout-contrib-router";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {ActivityLog, ActivityLogDto, IdeaDto} from "../../../api/generated";
import {activityLogApi} from "../../../api/api-wrapper";
import {asyncComputed} from "knockout-async-computed/dist/knockout-async-computed";
import "knockout.chart";
import i18nextko from "../../../bindings/i18nko";
import TypeEnum = ActivityLog.TypeEnum;

class ActivityMonth {
    date: moment.Moment;
    year: number;
    month: number;
    monthName: string;
    activityCounts: Map<ActivityLogDto.TypeEnum, number>

    constructor(date: moment.Moment) {
        this.date = date.startOf('month');
        this.year = date.year();
        this.month = date.month();
        this.monthName = date.format('MMMM');
        this.activityCounts = new Map<ActivityLogDto.TypeEnum, number>();
    }

    public addActivityCount(activity: ActivityLogDto) {
        if (!this.activityCounts.has(activity.type)) {
            this.activityCounts.set(activity.type, 0);
        }
        this.activityCounts.set(activity.type, this.activityCounts.get(activity.type) + 1);
    }

    public getActivityCount(activityType: ActivityLogDto.TypeEnum) {
        if (this.activityCounts.has(activityType)) {
            return this.activityCounts.get(activityType);
        }
        return 0;
    }
}

class ViewModelContext extends Context {
    ideas: IdeaDto[];
}

class ViewModel extends BaseReportsViewModel {

    /**
     * Activities of type USERLOGIN
     */
    public userLogins: KnockoutObservable<ActivityLogDto[]>;

    /**
     * Activities of type IDEACREATE, EVALUATIONSUBMITTED, EVALUATIONASSESSED
     */
    public ideaActivities: KnockoutObservable<ActivityLogDto[]>;

    /**
     * Activities of type IDEALIKE, IDEACOMMENT, IDEASHARE
     */
    public communityActivities: KnockoutObservable<ActivityLogDto[]>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();

        // Default start date is one year ago
        // Do not read / store these filters in global state
        this.dateFromFilter = ko.observable(moment().subtract(1, 'years').startOf('month').toDate());
        this.dateToFilter = ko.observable(null);

        this.userLogins = asyncComputed(async () => {
            return this.loadActivities(TypeEnum.USERLOGIN);
        }, []);

        this.ideaActivities = asyncComputed(async () => {
            return this.loadActivities(TypeEnum.IDEACREATE, TypeEnum.EVALUATIONSUBMITTED, TypeEnum.EVALUATIONASSESSED);
        }, []);

        this.communityActivities = asyncComputed(async () => {
            return this.loadActivities(TypeEnum.IDEALIKE, TypeEnum.IDEACOMMENT, TypeEnum.IDEASHARE);
        }, []);
    }

    /**
     * Load activities for one or more types
     * @param activityTypes
     */
    private loadActivities(...activityTypes: ActivityLogDto.TypeEnum[]): Promise<ActivityLogDto[]> {
        const since = this.dateFromFilter() ? moment(this.dateFromFilter()).format('YYYY-MM-DD') : undefined;
        const until = this.dateToFilter() ? moment(this.dateToFilter()).format('YYYY-MM-DD') : undefined;

        // create a promise for each type
        const activityPromises: Promise<ActivityLogDto[]>[] = [];
        activityTypes.forEach(activityType => activityPromises.push(
            activityLogApi.queryActivityLogs(<any>activityType, since, until, undefined, undefined)
        ));

        // return the result as flat array
        return Promise.all(activityPromises)
            .then((result) =>
                result.reduce((prev, cur) => prev.concat(cur), [])
            );
    }

    /**
     * Get the idea chart data.
     */
    public ideaChartData(): KnockoutComputed<any> {
        return ko.pureComputed(() => {
            const activityMonths: ActivityMonth[] = this.groupByMonth(this.ideaActivities())();
            const result = this.chartData(activityMonths, TypeEnum.IDEACREATE, TypeEnum.EVALUATIONSUBMITTED, TypeEnum.EVALUATIONASSESSED)();
            return result;
        });
    }

    /**
     * Get the user login chart data.
     */
    public loginChartData(): KnockoutComputed<any> {
        return ko.pureComputed(() => {
            const activityMonths: ActivityMonth[] = this.groupByMonth(this.userLogins())();
            const result = this.chartData(activityMonths, TypeEnum.USERLOGIN)();
            return result;
        });
    }

    /**
     * Get the community chart data.
     */
    public communityChartData(): KnockoutComputed<any> {
        return ko.pureComputed(() => {
            const activityMonths: ActivityMonth[] = this.groupByMonth(this.communityActivities())();
            const result = this.chartData(activityMonths, TypeEnum.IDEALIKE, TypeEnum.IDEACOMMENT, TypeEnum.IDEASHARE)();
            return result;
        });
    }

    /**
     * Group the activities by month.
     * @param activities
     */
    private groupByMonth(activities: ActivityLogDto[]): KnockoutComputed<ActivityMonth[]> {
        return ko.pureComputed(() => {
            const activitiesByMonth: Map<string, ActivityMonth> = activities
                .reduce((activitiesMap: any, activity: ActivityLogDto) => {
                    const activityDate = moment(activity.timestamp);
                    const key = activityDate.year() + '' + activityDate.month();
                    if (!activitiesMap.has(key)) {
                        activitiesMap.set(key, new ActivityMonth(activityDate));
                    }
                    activitiesMap.get(key).addActivityCount(activity);
                    return activitiesMap;
                }, new Map<string, ActivityMonth>());

            return Array.from(activitiesByMonth.values())
                .sort((month1, month2) =>
                    month1.date.isBefore(month2.date) ? 1 : -1
                );
        });
    }

    /**
     * Convert data for the chart.
     */
    private chartData(activityMonths: ActivityMonth[], ...activityTypes: ActivityLogDto.TypeEnum[]): KnockoutComputed<any> {
        return ko.pureComputed(() => {
            return {
                labels: activityMonths.map(month => [month.monthName, month.year]),
                datasets: activityTypes.map((type, index) => this.dataset(activityMonths, type, index))
            };
        });
    }

    /**
     * Create a dataset for a activity type
     * @param activityMonths
     * @param activityType
     * @param index
     */
    private dataset(activityMonths: ActivityMonth[], activityType: ActivityLogDto.TypeEnum, index: number) {
        const color = this.chartColors()[index];
        return {
            label: i18nextko.t('admin.reports.activitiesOverview.type.' + activityType.toString())(),
            backgroundColor: color.light,
            borderColor: color.dark,
            pointColor: color.dark,
            pointStrokeColor: "#fff",
            pointHighlightFill: "#fff",
            pointHighlightStroke: color.dark,
            data: activityMonths.map(activityMonth => activityMonth.getActivityCount(activityType))
        }
    }

    /**
     * Define some chart colors.
     */
    private chartColors(): any[] {
        return [
            {light: "rgba(0, 122, 155, 0.7)", dark: "rgba(0, 122, 155, 1)"},
            {light: "rgba(255, 220, 0, 0.7)", dark: "rgba(255, 220, 0, 1)"},
            {light: "rgba(220, 53, 69, 0.7)", dark: "rgba(220, 53, 69, 1)"}
        ]
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./activitiesOverview.html'),
    componentName: "activitiesOverview",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Aktivitäten Übersicht`;
        return Promise.resolve();
    }
};
