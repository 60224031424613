import {Context} from "@profiscience/knockout-contrib-router";
import {autobind} from "knockout-decorators";
import * as ko from "knockout";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {EvaluationDto} from "../../../api/generated";
import {isFilterActive} from "../../ideas/ideaUtils";
import {evaluationApi} from "../../../api/api-wrapper";

class ViewModelContext extends Context {
    evaluations: EvaluationDto[];
}

interface Period {
    name: string;
    evaluations: number;
    percentage: number;
}

class ViewModel extends BaseReportsViewModel {

    /**
     * The ideas.
     */
    public evaluations: EvaluationDto[];

    /**
     * The selected idea type.
     */
    public ideaTypeFilter: KnockoutObservable<string>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        super();
        this.evaluations = ctx.evaluations.filter(evaluation => evaluation.submittedTimestamp);
        this.ideaTypeFilter = ko.observable("");
    }

    /**
     * Get the ideas filtered and sorted.
     */
    @autobind
    public evaluationsFiltered(): KnockoutComputed<EvaluationDto[]> {
        return ko.pureComputed(() => {
            return this.evaluations.filter(evaluation => {
                if (this.dateFromFilter() !== undefined
                    && moment(evaluation.created).isBefore(moment(this.dateFromFilter()))) {
                    return false;
                }
                if (this.dateToFilter() !== undefined
                    && moment(evaluation.created).isAfter(moment(this.dateToFilter()).endOf('day'))) {
                    return false;
                }
                if(isFilterActive(this.ideaTypeFilter) && evaluation.idea.type && evaluation.idea.type.toString() !== this.ideaTypeFilter()) {
                    return false;
                }
                return true;
            })
        });
    }

    @autobind
    public periods(): KnockoutComputed<Period[]> {
        return ko.pureComputed(() => {
            let periods: Period[] = [];

            let periodMap = new Map();
            periodMap.set("21-days", 0);
            periodMap.set("42-days", 0);
            periodMap.set("63-days", 0);
            periodMap.set("84-days", 0);
            periodMap.set("longer", 0);

            let evaluationsTotal = this.evaluationsFiltered()().length;
            this.evaluationsFiltered()().forEach(evaluation => {

                if(evaluation.submittedTimestamp) {
                    let days: number = moment(evaluation.submittedTimestamp).endOf('day')
                            .diff(moment(evaluation.created).endOf('day'), 'days');
                    if(days <= 21) {
                        periodMap.set("21-days", periodMap.get("21-days") +1);
                    } else if (days <= 42) {
                        periodMap.set("42-days", periodMap.get("42-days") +1);
                    } else if (days <= 63) {
                        periodMap.set("63-days", periodMap.get("63-days") +1);
                    } else if (days <= 84) {
                        periodMap.set("84-days", periodMap.get("84-days") +1);
                    } else {
                        periodMap.set("longer", periodMap.get("longer") +1);
                    }
                }
            });

            for (let [key, value] of periodMap) {
                periods.push({
                    name: key,
                    evaluations: value,
                    percentage: this.percentage(evaluationsTotal, value)
                })
            }

            return periods;
        });
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./evaluationPeriods.html'),
    componentName: "evaluationPeriods",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Report Bewertungsdauer`;
        return Promise.all([
            evaluationApi.getEvaluations().then(evaluations => {
                ctx.evaluations = evaluations
            })
        ]);
    }
};
