import * as ko from "knockout";
import {Context} from "@profiscience/knockout-contrib-router";
import * as moment from "moment";
import {BaseReportsViewModel} from "./BaseViewModel";
import {UserScoreDto} from "../../../api/generated";
import {rankingApi} from "../../../api/api-wrapper";
import {asyncComputed} from "knockout-async-computed/dist/knockout-async-computed";
import "knockout.chart";

class UserScoreCount {

    type: UserScoreDto.TypeEnum;
    count: number;
    points: number;

    constructor(userScore: UserScoreDto) {
        this.type = userScore.type;
        this.count = 1;
        this.points = userScore.points;
    }

    public addUserScore(userScore: UserScoreDto) {
        if (userScore.type === this.type) {
            this.count++;
            this.points = this.points + userScore.points;
        }
    }
}

class ViewModel extends BaseReportsViewModel {

    public userScores: KnockoutObservable<UserScoreDto[]>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: Context) {
        super();

        // Default start date is one year ago
        // Do not read / store these filters in global state
        this.dateFromFilter = ko.observable(moment().subtract(1, 'years').startOf('month').toDate());
        this.dateToFilter = ko.observable(null);

        this.userScores = asyncComputed(async () => {
            const since = this.dateFromFilter() ? moment(this.dateFromFilter()).format('YYYY-MM-DD') : undefined;
            const until = this.dateToFilter() ? moment(this.dateToFilter()).format('YYYY-MM-DD') : undefined;

            return rankingApi.queryUserScores(undefined, since, until, undefined)
        }, []);
    }

    /**
     * Get the user scores grouped by type.
     */
    public userScoreCounts(): KnockoutComputed<UserScoreCount[]> {
        return ko.pureComputed(() => {
            const userScoreMap: Map<string, UserScoreCount> = this.userScores()
                .reduce((map: any, userScore: UserScoreDto) => {
                    const key = userScore.type;
                    if (!map.has(key)) {
                        map.set(key, new UserScoreCount(userScore));
                    } else {
                        map.get(key).addUserScore(userScore);
                    }
                    return map;
                }, new Map<string, UserScoreCount>());

            return Array.from(userScoreMap.values());
        });
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./activitiesCounts.html'),
    componentName: "activitiesCounts",
    loader: (ctx: Context) => {
        document.title = `${document.title} - Report Aktivitäten`;
        return Promise.resolve();
    }
};
